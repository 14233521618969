.getHelp {
	@extend .container-fluid;
	transition: max-height .35s;
	max-height: 0;
	overflow: hidden;
	display: none;
}

.getHelp.isExpanded {
	max-height: 999px;
	display: block;
}

.getHelp-zip {
	position: relative;

	.section & {

		display: inline-block;
		width: 47%;

		input:not([type=submit]):not([type=radio]):not([type=checkbox]) {
			width: 100% !important;
		}

	}
}

.getHelp-zip input {
	padding-right: 25px;
}

.getHelp-geolocate {
	position: absolute;
	right: 10px;
	top: 12px;
	cursor: pointer;
}

.getHelp-title {}

.getHelp-steps {
	margin: 0 0 0 10px;
	position: relative;
}

.getHelp-steps .slick-slide {
	height: auto;
}

.getHelp-steps:before {
	content: "";
	height: 150px;
	top: 0;
	left: -10px;
	width: 2px;
	background: $greyLighter;
	position: absolute;
	margin: 0 35px 0 0;
}

.getHelp-form {
	//width: 100%;
	background: white;
	z-index: 10;
	overflow: hidden;
	display: block;

	.section & {

		.getHelp-input,
		input:not([type=submit]):not([type=radio]):not([type=checkbox]) {
			width: 50%;
			display: inline-block;
		}

		input[type=submit] {
			margin: 0;
		}
	}
}

.getHelp-form:focus {
	outline: none;
}

.getHelp-form:first-child {
	z-index: 20;
}

.getHelp-arrow {
	font-size: 36px;
	cursor: pointer;
	color: $grey;
	float: left;
}

.getHelp-categories {
	@extend .u-resetList;
	column-count: 3;
	column-gap: 10px;
	margin: 0 0 0 25px;
}

.getHelp-categories li {
	margin: 0 0 5px;
	font-size: 14px;
}

select.getHelp-categories {
	height: 43px;
	font-size: 14px;
	margin: 1em 0 0;
	width: 84%;
}

.navItem-getHelp {

	.fa,
	.fab,
	.far,
	.fas,
	.fad,
	.fal {
		@media (min-width:$screen-md-min) {
			display: none;
		}
	}
}

.bridges-container {
	//display:flex;
	margin-top: 2em;
}

#bridgesMap {
	width: 100%;
	height: 75vh;
}

.bridgesResults {
	line-height: 1.3;
	color: #787878;
}

.bridgesResults p:not(.bridgesResult-address) {
	margin-top: 0;
	margin-bottom: 0;
}

.bridgesResults-single {
	position: relative;
	background: #f4f4f4;
	transition: background .35s;
	padding: 15px 35px 15px 50px;
	font-size: 13px;
	cursor: pointer;
	border-bottom: 1px solid #EFEEEE;
}

.bridgesResults-single:hover,
.bridgesResults-single.isSelected {
	background: linear-gradient(to bottom, rgba(216, 216, 216, 1) 0%, rgba(235, 235, 235, 1) 100%);
}

.bridgesResults-single:before {
	background-image: url(../images/global/location-pin.png);
	background-repeat: no-repeat;
	background-size: 20px 30px;
	content: "";
	width: 20px;
	height: 30px;
	position: absolute;
	left: 15px;
	top: 15px;
	z-index: 20;
}

.bridgesResults-single .fa,
.bridgesResults-single svg {
	height: 22px;
	font-size: 22px;
	display: inline-block;
	vertical-align: middle;
	color: black;
	fill: black;
	margin-right: 9px;
	transition: all .35s;

	&:hover {
		fill: #787878;
		color: #787878;
	}
}

.bridgesResults-single svg {
	margin-right: 6px;
	margin-left: 15px;
}

.bridgesResult-title {
	font-size: 17px;
	font-weight: bold;
	color: #404554;
}

.bridgesResult-address {
	margin: 0 0 5px;
}

.bridgesResult-icon {
	padding: 0 5px;
}

.traffickingText,
.domesticViolenceText {
	overflow: hidden;
	max-height: 0;
	transition: max-height .35s;

	p {
		margin: 0;
	}

	&.isShown {
		max-height: 500px;
	}
}

.bridges-introSection {
	padding-top: 15px;
	padding-bottom: 15px;
}

@mixin desktop() {

	.bridges-container,
	.getHelp-form {
		display: flex;
	}

	.getHelp {
		display: block;
	}

	.getHelp-zip {
		margin: 0 5px 0 0;
		min-width: 35%;

		.section & {
			min-width: 18%;
		}
	}

	#bridgesMap {
		width: 600px;
		height: 550px;
		flex: 2 1;
	}

	.bridgesResults {
		max-height: 550px;
		flex: 1 1;
		overflow-y: scroll;
	}

	#bridgesMap[style*="none"]+.bridgesResults {
		text-align: center;
		padding-bottom: 3em;
	}

	#bridgesMap[style*="none"]+.bridgesResults:after {
		display: none;
	}

	.bridgesResults-single {
		border: none;
	}

	select.getHelp-categories {
		margin: 0;
		width: auto;
	}

	.bridges-introSection {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}
