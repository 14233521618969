.alert {
    color: white;
    padding: .75em 1em;
    text-align: center;
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    top: 10px;
    font-size: 11px;
    height: 63px;
    @media (min-width:$screen-sm-min) {
    	font-size: 14px;
    	padding: 1em;
    	height: auto;
    }
}

.alert a {
    color: white;
    text-decoration: underline;
    opacity: 1;
    transition:opacity .35s;
    &:hover {
    	opacity: .7;
    }
}