// http://codepen.io/paulobrien/pen/FKAxH

html, body, #page {
	margin:0;
	padding:0;
	height:100%;
}

#page {
	//display:table;
	//width:100%;
	/* removes horizontal scroll */
	//overflow-x:hidden;

	// The above code is causing issues with the hero slider, so it is removed for now - JB 3/14/2016
	height: auto;
}

.header, .footer, .content {
	display:block;
	
	//display:table-row;
	// The above code is causing issues with the hero slider, so it is removed for now - JB 3/14/2016
	
	width:100%;
}

.header, .footer {
	//height:1px;

	// The above code is causing issues with the hero slider, so it is removed for now - JB 3/14/2016
}