.gdosResults {
	font-size: 14px;
	line-height: 1.2;	
}

.gdosResults a {
	text-decoration:none;
}

.gdosResults-category {
	border-bottom:1px solid $brand;
	margin:1em 0 0;
}

.gdosDetails {
	transition:max-height .35s;
	max-height:0;
	overflow:hidden;
	background:#f7f7f7;
	background:url(../images/global/shield.svg) no-repeat center left;
	background-size: 50px;
	padding-left: 70px;
	line-height: 1.2;
}

.gdosDetails.isActive {
	max-height:100px;
}

#map {
	max-height: 75vh;
}

@media (min-width:$screen-md-min) {
	#map {
		max-height: 500px;
	}
}