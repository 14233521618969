.mapControl-container {
    position: relative;
    overflow: hidden;
    border-top: 2px solid $greyLighter;
    border-bottom: 2px solid $greyLighter;

    .mapControl {
        display: none;
    }

    .mapControl-results-container {
        background: white;
        z-index: 2;
        padding: 25px;
        display: flex;
        flex-direction: column;

        h3,
        .mapControl-geocode,
        .form-search,
        .mapControl-showingResults,
        .mapControl-error {
            flex: none;
            width: 100%;
        }

        h3 {
            font-size: 20px;
        }

        .mapControl-nudge {
            font-weight: 500;
            display: none;
        }

        .form-search .text {
            width: calc(100% - 40px) !important;
            max-width: none;
        }

        .mapControl-error {
            color: red;
            font-weight: bold;
            display: none;
        }

        .mapControl-showingResults {
            display: none;

            a {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .mapControl-results {
            flex: 1;
            overflow-y: auto;
            margin: 25px -25px -25px;
            border-top: 2px solid $greyLighter;
            position: relative;

            &.local-loading {
                overflow: hidden;
            }

            >div {
                border-bottom: 1px solid $greyLighter;
                padding: 25px;
                transition: background-color .35s;

                a.btn {
                    padding: 10px 20px;
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 16px;
                    margin: 0 0 10px 15px;
                }

                &:hover {
                    background: $greyLighter;
                }

                &.active {
                    box-shadow: inset 0 0 0 2px $brand;
                }
            }

            &.mapControl-mobile-hasExtra {
                .mapControl-mobile-extra {
                    display: none;
                }

                .mapControl-mobile-showExtra {
                    display: block;
                    padding: 25px 0;
                    text-align: center;
                    text-decoration: underline;
                    cursor: pointer;
                }

                &.mapControl-mobile-showingExtra {
                    .mapControl-mobile-extra {
                        display: block;
                    }

                    .mapControl-mobile-showExtra {
                        display: none;
                    }
                }
            }
        }
    }
}


@mixin tablet() {
    .mapControl-container {
        height: 500px;
        max-height: calc(100vh - 100px);

        .mapControl {
            display: block;
            position: absolute !important;
            top: 0;
            right: 0;
            left: 400px;
            bottom: 0;
            z-index: 1;
        }

        .mapControl-results-container {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 400px;
            box-shadow: 4px 1px 4px rgba(0, 0, 0, .2);

            .mapControl-mobile-extra {
                display: block !important;
            }

            .mapControl-mobile-showExtra {
                display: none !important;
            }
        }
    }
}

@media (min-width:$screen-sm-min) {
    &:not(.preview-container) {
        @include tablet();
    }
}

&.preview-container.desktop,
&.preview-container.tablet {
    @include tablet();
}
