.usMap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 406px;

    &,
    svg {
        width: 600px;
        max-width: 100%;
        max-height: calc((100vw - 60px) * .666);
        margin: auto;
    }

    svg {
        height: auto !important;
    }

}

.stateMapDetails {
    border-radius: 3px;
    background: $greyLighter;
    padding: 0 30px;
    height: 0;
    overflow: hidden;

    iframe {
        margin: auto auto 30px;
        display: block;
    }

    &.is-shown {
        padding: 30px;
        height: auto;
    }
}
