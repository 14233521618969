.section {
	padding-top: 1em;
	padding-bottom: 1em;

	.localSite & {
		overflow: hidden;

		&.localName {
			padding: 5em 0 4em;

			@media (max-width:$screen-sm-max) {
				padding: 2em 0;
			}

			&.no-padding-top {
				//margin-top: -.5em;
			}
		}

		&.greatestNeed {
			padding: 2.75em 0 4em;

			@media (max-width:$screen-sm-max) {
				padding: 1.5em 0 2.5em;
			}
		}

		&.greatestNeed,
		&.localName {
			@media (max-width:$screen-sm-max) {
				padding-left: 2em;
				padding-right: 2em;
			}
		}

	}
}

.full-width-img {
	width: 100% !important;
	height: auto !important;
}

.existingContent,
.landingpage-container {
	@import "../vendor/bootstrap/old_bootstrap";

	.row {
		margin-left: -15px;
		margin-right: -15px;
	}

	>h2:first-child,
	>p:first-child,
	>div:first-child {
		>img:first-child {
			@extend .full-width-img;
		}

		>strong:first-child {
			>img:first-child {
				@extend .full-width-img;
			}
		}
	}

	>img:first-child {
		@extend .full-width-img;
	}

	h3.locations,
	h4.locations,
	h5.locations {
		font-size: 20px;
		font-weight: 600;
	}
}

.form-search .input-append .search-query,
.form-search .input-prepend .search-query {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0
}

.form-search .input-append .search-query {
	-webkit-border-radius: 14px 0 0 14px;
	-moz-border-radius: 14px 0 0 14px;
	border-radius: 14px 0 0 14px
}

.form-search .input-append .btn {
	-webkit-border-radius: 0 14px 14px 0;
	-moz-border-radius: 0 14px 14px 0;
	border-radius: 0 14px 14px 0
}

.form-search .input-prepend .search-query {
	-webkit-border-radius: 0 14px 14px 0;
	-moz-border-radius: 0 14px 14px 0;
	border-radius: 0 14px 14px 0
}

.form-search .input-prepend .btn {
	-webkit-border-radius: 14px 0 0 14px;
	-moz-border-radius: 14px 0 0 14px;
	border-radius: 14px 0 0 14px
}

.form-search input,
.form-inline input,
.form-horizontal input,
.form-search textarea,
.form-inline textarea,
.form-horizontal textarea,
.form-search select,
.form-inline select,
.form-horizontal select,
.form-search .help-inline,
.form-inline .help-inline,
.form-horizontal .help-inline,
.form-search .uneditable-input,
.form-inline .uneditable-input,
.form-horizontal .uneditable-input,
.form-search .input-prepend,
.form-inline .input-prepend,
.form-horizontal .input-prepend,
.form-search .input-append,
.form-inline .input-append,
.form-horizontal .input-append {
	display: inline-block;
	*display: inline;
	margin-bottom: 0;
	vertical-align: middle;
	*zoom: 1
}

.form-search .hide,
.form-inline .hide,
.form-horizontal .hide {
	display: none
}

.form-search label,
.form-inline label,
.form-search .btn-group,
.form-inline .btn-group {
	display: inline-block
}

.form-search .input-append,
.form-inline .input-append,
.form-search .input-prepend,
.form-inline .input-prepend {
	margin-bottom: 0
}

.form-search .radio,
.form-search .checkbox,
.form-inline .radio,
.form-inline .checkbox {
	padding-left: 0;
	margin-bottom: 0;
	vertical-align: middle
}

.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
	float: left;
	margin-right: 3px;
	margin-left: 0
}

.form-search {
	margin: .6875em 0 0
}

.form-search .text {
	width: auto !important;
	height: 39px !important;
	padding: 0 9px !important;
	margin: 0 -6px 0 0 !important;
	border-right: none !important;
	min-width: 200px;
}

.form-search .text::-webkit-input-placeholder {
	height: 39px;
	font-size: 1em !important
}

.form-search .text:-moz-placeholder {
	height: 39px
}

.form-search .text::-moz-placeholder {
	height: 39px
}

.form-search .text:-ms-input-placeholder {
	height: 39px
}

.form-search .submit {
	width: 40px !important;
	height: 39px !important;
	font: 0/0 a !important;
	color: transparent;
	text-shadow: none;
	background: url("//static.salvationarmy.org/us-east-1/templates/symphony/static_resources/images/global/old_sprite.png") !important;
	background: none,
	url("//static.salvationarmy.org/us-east-1/templates/symphony/static_resources/images/global/old_sprite.svg") !important;
	background-color: transparent !important;
	background-color: #e0e0e0 !important;
	background-position: 6px 6px !important;
	border: 0;
	border: 0;
	border: 1px solid #a1a1a1;
	border-left: none;
	-webkit-border-radius: 0
}

.form-search .submit:hover {
	background-color: #e0e0e0 !important;
	opacity: .7;
	filter: alpha(opacity=70);
	-webkit-transition: .25s;
	-moz-transition: .25s;
	-o-transition: .25s;
	transition: .25s
}

.form-zip {
	display: block;
	margin: 0 auto 20px auto;
	text-align: center
}

.form-zip .submit {
	background-position: 9px -207px !important
}

.form-zip .text:-moz-placeholder {
	font-size: 1em
}

.form-zip .text:-ms-input-placeholder {
	font-size: 1em
}

.form-zip .text::-webkit-input-placeholder {
	font-size: 1em
}

.gdos-heading {
	font-family: $sans-serif;
	font-weight: 600;
}

.gdos-search-container {
	>h2 {
		@extend .gdos-heading;
	}

	>script+h2 {
		padding-top: 470px;
	}
}

#gdos_results {
	padding: 0;
	width: 60% !important;
}

#gdos_results {
	float: none !important;
	width: auto !important;
	padding: 0;
}

#gdos_results ul li {
	margin: 0;
	padding-left: 1em;
	text-indent: -1em;
}

#gdos_results ul {
	padding: 0;
	margin: 0
}

#gdos_results .gdos_result ul {
	margin: 0;
	line-height: 1.2;
	list-style-type: none;
	list-style-image: none;
	-moz-column-count: 2;
	-moz-column-gap: 20px;
	-webkit-column-count: 2;
	-webkit-column-gap: 20px;
	column-count: 2;
	column-gap: 20px
}

#gdos_results .gdos_result ul li {
	margin: 0
}

#gdos_results .centerdetails {
	padding-right: 10px;
}

#gdos_results+#right {
	position: absolute;
	top: 0px;
	left: 15px;
	right: 15px;
	margin-top: 0 !important;
	text-align: left !important;
	margin-right: auto;
	margin-left: auto;
	width: 85% !important;
	max-width: 940px;

	br {
		display: none;
	}

	div[style="float: right;"] {
		@extend .form-search;
		float: none !important;

		.text {
			width: calc(100% - 40px) !important;
			max-width: 315px;

			@media screen and (max-width: 979px) and (min-width: 769px) {
				max-width: 315px !important;
			}

			@media screen and (max-width: 767px) {
				max-width: 360px !important;
			}
		}
	}

	h2:not(.gdos-heading) {
		visibility: hidden;
		font-family: $sans-serif;
		font-weight: 600;
		position: relative;

		&:after {
			content: "Location Search";
			visibility: visible;
			position: absolute;
			left: 0;
		}
	}

	p {

		br:first-of-type,
		br:first-of-type+br {
			display: block;
		}
	}

	a {
		float: left;
		width: 33.33%;
		padding-right: 10px;
	}
}

@media screen and (max-width:979px) {
	#gdos_results tr td:first-child {
		display: none
	}

	#gdos_results tr td[width="50%"] {
		display: block;
		width: 100%
	}
	#gdos_results .gdos_result ul li {
		margin: 0
	}

	#gdos_results+#right {
		float: none !important;
		width: auto !important;
		text-align: left !important;
	}

	#gdos_results+#right div[style="float: right;"] {
		float: none !important;
	}
}

@media screen and (min-width:769px) and (max-width:979px) {
	.sidebar .form-search .text {
		max-width: 125px
	}

	.form-search .text {
		max-width: 180px
	}

	.location .form-search {
		float: left
	}

	.location .form-search .text {
		max-width: 130px
	}
}

@media screen and (max-width:767px) {
	#gdos_results .gdos_result ul {
		-moz-column-count: 1;
		-moz-column-gap: 0;
		-webkit-column-count: 1;
		-webkit-column-gap: 0;
		column-count: 1;
		column-gap: 0
	}

	#gdos_results+#right {
		a {
			width: 50%;
		}
	}
}

@media screen and (max-width:419px) {
	#gdos_results+#right {
		a {
			font-size: 90%;
			white-space: nowrap;
			width: auto;
		}
	}
}


.searchresults {
	.centername {
		font-family: $sans-serif;
		font-weight: bold;
	}

	.gdos_result {
		margin-top: 5px;
		border-top: 1px solid #cccccc;
		padding-top: 10px;
		margin-bottom: 60px;
		font-size: 90%;
	}

	.gdos_result ul {
		list-style-type: none;
		list-style-image: none;
	}

	#gdos_results {
		list-style-type: none;
		list-style-position: inside;
		margin-top: 15px;
	}

	#gdos_results li {
		margin: 10px 0 1px 0;
	}
}

.section-container {
	@extend .container;
}

.section-row {
	@extend .row;
}

.section-row img,
.section-row svg {
	margin: 0 auto;
	display: block;
}

.section-title {
	font-size: 18px;
	margin-bottom: 1em;
}

.section-title br,
.section-subtitle br {
	display: none;
}

.section-title,
.section-subtitle {
	@extend .u-textCenter;
	margin-right: 10px;
	margin-left: 10px;
}

.flyoutContainer+.section,
#emailCapture+.section {
	padding-top: 3em;

	.stories & {
		padding-top: 1em;

		@media (min-width:$screen-sm-min) {
			padding-top: 3em;
		}
	}
}

@mixin desktop() {
	#gdos_results+#right {
		max-width: 1130px;
	}
	
	.section {
		padding: 2em 0;
	}

	.section-title {
		margin-bottom: 50px;
		//font-size: 30px;
	}

	.section-title,
	.section-subtitle {
		margin-right: 0px;
		margin-left: 0px;
	}

	.section-title+.section-subtitle {
		margin: -50px 0 50px;
	}

	.section-title br,
	.section-subtitle br {
		display: block;
	}

	.section-row {
		display: flex;
		align-items: center;
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}
