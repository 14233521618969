.pressRelease {
	color: $greyDark;
}

.pressRelease-synopsis {
	margin-bottom: 2em;
}

.pressArchive {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.pressArchive-subList {
	list-style-type: none;
	padding: 0;
}

.pressArchive-single {
	line-height: 1.2;
	padding: 1em;
	border-right: 1px solid #ccc;
	list-style-type: none;

	a {
		color: $greyDark;
		text-decoration: none;
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			color: $brand;
		}
	}

	time {
		font-size: 12px;
		color: #6B6B6B;
	}

	&.no-border {
		border: none;
	}
}

.newsContent {
	.news-item-row {
		margin-left: -5px;
		margin-right: -5px;

		.news-item-col {
			padding: 5px;

			.news-item {
				padding: 15px;
				background: $offWhite;
				position: relative;

				.news-date {
					float: right;
					font-size: 13px;
				}

				.news-btn {
					font-size: 16px;
					padding: 5px 30px;
					line-height: 20px;
					display: block;
				}

				&:after {
					content: '';
					display: table;
					clear: both;
				}
			}
		}
	}
}


//@media (min-width:$screen-xs-min) {
.pressArchive {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;
}

.pressArchive-subList {
	border-left: 3px solid rgb(234, 234, 234);
	box-sizing: border-box;
	padding-left: 2em;
	flex: 1 1;

	&:first-child {
		padding-left: 0;
		border: none;
	}

	li {
		padding-right: 2em;
	}
}

.newsContent {
	.news-item-row {
		.news-item-col {
			.news-item {
				padding: 15px 15px 65px;
				min-height: 368px;

				.news-btn {
					position: absolute;
					left: 15px;
					right: 15px;
					bottom: 15px;
				}
			}
		}
	}
}

//}
