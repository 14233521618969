.nav {
	list-style-type:none;
	margin:0;
	padding:0;
}

.navItem {
	@extend .u-inlineBlock;
	display:none;
}

.navItem.isShownOnMobile {
	@extend .u-inlineBlock;
}

.navItem-icon {
	vertical-align: middle;
	position: relative;
	top: -2px;
	margin: 0 2px 0 0;
	transition:color .35s;
}

.navItem-iconLocations {
	font-size: 18px;
	top:-1px;
	position:relative;
}

.navItem-iconDonate {
	font-size: 19px;
}

.navItem-link {
	color:black;
	text-decoration: none;
	font-size: 12px;
	line-height:20px;
	transition:color .35s;
	border:1px solid transparent;
	border-radius:3px;
}

.navItem-link:hover {
	color:white;
}

.navItem-link:hover .navItem-icon {
	color:$grey;
}

.navItem-btn {
	border-color:black;
	transition:all .35s;
	margin: 0px 0 0 5px;
    padding: 4px 12px;
	font-size:12px;
	cursor: pointer;
	&.donateHeader-expanded {
		color:white !important;
		border-color:$brand;
		background-color:$brand;
	}
}

.navItem-btn:hover {
	color:black;
}

.nav.hasScrolled {
	background:white;
}

@mixin desktop() {
	.navItem {
		margin:0 10px 0 0;
		display:inline-block;
	}
	.navItem-link {
		color:white;
		font-weight:600;
		padding:5px 15px;
		border-radius:5px;
		font-size: 15px;
	}
	.navItem-link:hover .navItem-icon {
		color:$brand;
	}
	.navItem-btn {
		//margin:0 0 0 .5em;
		border-color:white;
		padding: 5px 30px !important;
		font-size: 15px;
	}
	.navItem-btn:hover {
		color:white !important;
		border-color:$brand;
		background-color:$brand;
	}
	.navItem-iconLocations {
		font-size: 22px;
	}
	.navItem-iconDonate,
	.navItem-link {
		letter-spacing: 1px;
	}
	.globalHeader .navItem-link:hover {
		color:$brand;
	}

	.globalHeader .navItem-btn:hover {
		color:black;
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}

#postcodeTextBox {
    height: 30px;
    margin-top: -5px;
    width: 175px;
    font-family: trajan-pro-3,sans-serif;
    font-size: 15px;
	@media only screen and (max-width: 768px) {
	    height: 24px;
	    margin-top: -5px;
	    width: 112px;
	    font-family: trajan-pro-3,sans-serif;
		font-size: 11px;
	}
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		line-height: 5px;
	}
}

#postcodeTextBoxIE {
	@media only screen and (max-width: 768px) {
	}
}

#postcodeSubmitButton {
    width: 40px!important;
    height: 39px!important;
    border: none;
    outline: 0;
    color: hsla(0, 0%, 0%, 0);
    background-color: hsl(0, 0%, 63%);
    border-radius: 10px;
    transition: all .35s;
    padding: 10px 12px;
    margin: 0px 0px -9px;
    max-width: 150px;
    background: url(//static.salvationarmy.org/us-east-1/templates/symphony/static_resources/images/global/old_sprite.svg) no-repeat 0 -210px !important;
}

#formBox {
    margin: -4px -15px 0px;
}
