@mixin subMenu () {
	&.hasChildren {

		>ul.flyoutNav {
			background: $greyLighter;
			position: absolute;
			top: 48px;
			left: 0;
			right: 0;
			padding: 0;
			margin: 0;
			flex-direction: column;
			box-shadow: $shadowLight;
			max-width: 0;
			opacity: 0;
			transition: max-width .15s, opacity .15s;
			pointer-events: none;
			z-index: 10000000;

			li.flyoutNavItem {
				background: $greyLighter;
				position: relative;

				a.flyoutNavItem-link {
					color: black;

					&:hover {
						background: rgba(0, 0, 0, .1);
					}
				}

				&.hasChildren {
					>a.flyoutNavItem-link {
						padding-right: 30px !important;
						padding-left: 30px !important;
					}

					ul.flyoutNav {
						top: 0;
						left: 100%;
					}
					
					li.flyoutNavItem.hasChildren ul.flyoutNav {
						position: absolute;  // Ensure the submenu is positioned absolutely
						top: 0;  // Initially set top to 0 for all submenus
				
						// Offset grandchild submenu to align with its parent item
						// Adjust based on the current item’s position in the submenu
						transform: translateY(calc(var(--flyout-nav-item-index, 0) * 100%));
					}
				}
			}
		}

		&.viewingChild {
			>ul.flyoutNav {
				max-width: 100%;
				opacity: 1;
				pointer-events: all;
				position: absolute;
				
			}
		}

		&:hover {

			&.viewingChild {
				>ul.flyoutNav {
					max-width: 100%;
					opacity: 1;
					pointer-events: all;
					position: absolute;
				}
			}
		}
	}

	&.flyoutNavItem-back {
		.flyoutNavItem-link {
			padding-right: 30px;
			padding-left: 30px;

			i {
				right: auto;
				left: 0;
			}
		}
	}
}

.givingToolbar-toolbar {
	background: $brand;
	display: flex;
	overflow: auto;
	transform: translate3d(0, 0, 0);

	@media screen and (max-width:767px) {
		display: flex;
    	flex-wrap: wrap;
	}
	
	>div,
	>a {
		flex: 1 1 auto;
		display: flex;
		justify-content: center;
		color: white;
		text-transform: uppercase;
		padding: 5px;
		cursor: pointer;
		transition: all .35s;

		&:hover {
			background: $brandLight;
			text-decoration: none;
		}

		i {
			height: 38px;
			line-height: 38px;
			font-size: 28px;
			float: left;
			margin-right: 14px;
		}

		span.line {
			line-height: 38px;

			span {
				display: none;
			}

			span.mobile {
				@media screen and (min-width:768px) {
					display: none !important;
				}
				@media screen and (max-width:767px) {
					display: inline-block;
					min-width: 50px;
				}
			}
		}
		
	}

	.localSite & {
		top: 45px;
		left: 0;
		width: 100%;
		position: fixed;
		font-size: 13px;
		z-index: 500;

		@media (min-width:1450px) {
			font-size: 15px;
		}

		@media (min-width:$screen-md-min) {
			top: 66px;
		}
	}

	.localSite.home.hasSlides &,
	.localSite.ministry.hasSlides &,
	.localSite.waystogive.hasSlides &,
	.localSite .first-property+.heroContainer+&,
	.localSite .first-property+#page>.heroContainer:first-child+& {
		position: relative;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
		width: 100%;
	}

	.localSite.home.hasSlides.givingToolbar-isSticky &,
	.localSite.ministry.hasSlides.givingToolbar-isSticky &,
	.localSite.waystogive.hasSlides.givingToolbar-isSticky &,
	.localSite.givingToolbar-isSticky .first-property+.heroContainer+&,
	.localSite.givingToolbar-isSticky .first-property+#page>.heroContainer:first-child+& {
		top: 45px;
		position: fixed;
		z-index: 500;

		@media (min-width:$screen-md-min) {
			top: 66px;
		}
	}

	&.isMainNav {
		overflow: visible;
		font-size: 12px;

		ul.flyoutNav {
			list-style: none;
			padding: 0;
			margin: 0 auto;
			display: flex;
			width: 100%;
			@extend .container;

			.fa-angle-down {
				display: none;
			}

			li.flyoutNavItem {
				position: relative;
				list-style: none;
				flex: 1;

				a.flyoutNavItem-link {
					position: relative;
					height: 48px;
					line-height: 1em;
					color: white;
					text-decoration: none;
					flex: 1 1 auto;
					display: flex;
					justify-content: center;
					flex-direction: column;
					text-align: center;
					text-transform: uppercase;
					padding: 5px 10px;
					cursor: pointer;
					/* transition: all .35s; */

					i {
						position: absolute;
						/* right: 5px; */
						right: 0;
						top: 0;
						width: 35px;
						text-align: center;
						height: 43px;
						line-height: 43px;
						background: 0 0;
						cursor: pointer;
						font-size: 18px;
						z-index: 1;
					}

					&:hover {
						text-decoration: none;
					}

				}

				&:hover {
					background: $brandLight;
				}

				@include subMenu();
			}

			&.viewingChild {
				background-color: transparent !important;
				box-shadow: none !important;

				&>.flyoutNavItem {
					background-color: transparent !important;

					&>.flyoutNavItem-link {
						opacity: 0 !important;
						pointer-events: none !important;
					}
				}
			}

			&.closingMenus {

				&,
				* {
					transition: none !important;

				}

				.flyoutNav {
					display: none !important;
				}
			}
		}

		&>.flyoutNav>.flyoutNavItem>.flyoutNavItem-link>i {
			display: none !important;
		}

		/* always show first level submenu */
		&>.flyoutNav>.flyoutNavItem.hasChildren>ul.flyoutNav {
			max-width: 100% !important;
		}

		&>.flyoutNav>.flyoutNavItem.hasChildren:hover>ul.flyoutNav {
			opacity: 1;
			pointer-events: all;
		}

		&>.flyoutNav>.flyoutNavItem.hasChildren>.flyoutNav>.flyoutNavItem.flyoutNavItem-back,
		&>.flyoutNav>.flyoutNavItem.hasChildren>.flyoutNav>.flyoutNavItem.flyoutNavItem-overview {
			display: none !important;
		}
	}

	body:not(.localSite) & {
		display: none;
	}
}

@mixin tablet() {
	.givingToolbar-toolbar {
		&.isMainNav {
			font-size: 13px;

			@media (min-width:1450px) {
				font-size: 15px;
			}
		}

		>div,
		>a {
			i {
				float: left;
				margin-right: 14px;
			}

			span.line {
				span:not('.tablet') {
					display: none;
				}

				span.tablet {
					display: inline;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	:not(.preview-content)> {
		@include tablet();
	}
}

&.preview-container.tablet,
&.preview-container.desktop {
	@include tablet();
}

@mixin desktop() {
	.givingToolbar-toolbar {

		>div,
		>a {
			padding: 25px 0;

			.localSite & {
				padding: 14px 0;
			}

			i {
				font-size: 34px;

				.localSite:not(.home):not(.ministry) & {
					font-size: 20px;
					height: auto;
					line-height: 20px;
				}
			}

			span.line {
				line-height: 18px;
				display: block;

				.localSite:not(.home):not(.ministry) & {
					line-height: 1;
					display: inline;
				}

				span {
					display: inline;
				}
			}
		}

		body.localSite.givingToolbar-isSticky & {

			>div,
			>a {
				i {
					font-size: 20px;
					height: auto;
					line-height: 20px;
				}

				span.line {
					line-height: 1;
					display: inline;
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	:not(.preview-content)> {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}

.givingToolbar-links {
	color: $greyMedium;

	>div,
	>a {
		display: flex;
		padding: 10px 0;

		>* {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.givingToolbar-link {
			text-transform: uppercase;
			white-space: nowrap;
			width: 190px;

			>span {
				height: 38px;

				i {
					float: left;
					margin-right: 14px;
					font-size: 28px;
					line-height: 38px;
					height: 38px;
				}
			}
		}

		>i {
			width: 110px;
			font-size: 28px;
			text-align: center;
		}

		.givingToolbar-description {
			flex: 1;
		}
	}
}

.givingToolbar-text {
	display: inline-block;
	vertical-align: middle;
}

.givingToolbar-toolbar>a {
	text-decoration: none;
}

.emailCaptureModal {
	margin: 0 auto;
	background: $brand;
	color: white;
	max-width: 580px;
	padding: 2em;
	border-radius: 5px;
	position: relative;

	.mfp-close {
		color: white;
	}

	input[type="submit"] {
		border: 1px solid white;
		max-width: none;
		padding-left: 0;
		padding-right: 0;
	}

	.hearMore-error {
		opacity: 1;
		transition: opacity .3s;

		&.u-hidden {
			display: block;
			opacity: 0;
		}
	}

	input {
		width: 100% !important;
	}

	button.submit {
		border: 1px solid white;
		line-height: 22px;
	}

	.facebookLink {
		color: white;
		font-size: 12px;
		line-height: 1;
		text-decoration: none;
		display: inline-block;
		padding: 1em 0 0;
		margin: 2em 0 0;
		font-weight: 100;
		opacity: 1;

		&:hover {
			opacity: .7;
			transition: opacity .35s;
		}

		strong {
			font-weight: 600;
			display: block;
		}

		i {
			color: $brand;
			background: white;
			width: 20px;
			height: 20px;
			text-align: center;
			line-height: 20px;
			border-radius: 50%;
			margin: 2px 0 0 5px;
		}
	}

	div[class^="col-sm"] {
		padding-bottom: 5px !important;
	}

	@media (max-width: $screen-xs-max) {
		div[class^="col-sm"] {

			&.no-padding,
			&.no-padding-right {
				padding-right: 15px !important;
				padding-left: 15px !important;
			}
		}
	}
}

/* hide main nav giving toolbar on mobile */
@mixin mainNavMobile {
	.givingToolbar-toolbar {
		&.isMainNav {
			display: none;
		}
	}
}

@media (max-width:$screen-sm-max) {
	@include mainNavMobile();
}

&.preview-container.tablet,
&.preview-container.mobile {
	@include mainNavMobile();
}
