.globalHeader {
	@extend .container-fluid;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
	transition: all .35s;
	transform: translate3d(0, 0, 0);
	background: white;
	font-size: 14px;
	line-height: 1.4;
	padding-left: 0;
	padding-right: 0;

	// .localSite:not(.home) & {
	// 	background: white;
	// }
	&:after {
		content: "";
		width: 103%;
		height: 2px;
		background: rgba(0, 0, 0, .05);
		left: 0;
		position: absolute;
		bottom: -2px;
	}
}

.globalHeader input:not([type=submit]) {
	font-size: 17px;
}

.globalHeader-wrapper {
	//@extend .row-fluid;
	max-width: 1170px;
	margin: 0px auto;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 5px 15px;
}

.globalHeader-logoContainer,
.globalHeader-navContainer {
	flex-grow: 1;
}

.globalHeader-logoContainer {
	margin: 0;
	text-decoration: none;
}

.globalHeader-logo {
	height: 30px;
	width: 48px;
	opacity: 1;
	transition: all .35s;
	top: 3px;
	position: relative;
}

.globalHeader-logoContainer:hover .globalHeader-logo {
	filter: drop-shadow(0 0 0 10px #888);
	//transform:translate(-1px,-1px);
}

.globalHeader-tagline-container {
	@extend .serif;
	display: none;
}

.globalHeader-logoContainer:hover .globalHeader-tagline-container .globalHeader-tagline,
.globalHeader-logoContainer:hover .globalHeader-tagline-container .globalHeader-tagline-subtitle {
	color: $brand;
}

.globalHeader-navContainer,
.globalHeader-logoContainer,
.globalHeader-nav,
.globalHeader-menuTrigger {
	@extend .u-inlineBlock;
}

.globalHeader-navContainer {
	transition: all .35s;
	text-align: right;
	//width:100%;
}

.globalHeader-menuTrigger {
	color: black;
	padding: 8px 0 8px 17px;
	transition: color .35s;
	cursor: pointer;
	position: relative;
	z-index: 2001;
	line-height: 20px;
	font-size: 15px;
	//@extend .u-floatRight;
}

.globalHeader-menuTrigger:hover,
.globalHeader .globalHeader-menuTrigger:hover {
	color: $brand;
}

.globalHeader-menuIcon {
	font-size: 17px;
	float: left;
	margin: 0 5px 0 0;
	//top: 2px;
	position: relative;
	@extend .u-inlineBlock;
}

.globalHeader-siteTitle {
	color: white;
	font-size: 16px;
	font-weight: 600;
	text-decoration: none;

	&:hover {
		color: $brand;
	}

	//.isSticky & {
	color: black;

	//}
	@media (max-width:$screen-sm-max) {
		display: none;
	}
}

.globalHeader-menu-mobile {
	display: inline-block;
}

.globalHeader-menu-desktop {
	display: none;
	height: 36px;
	color: white;
	background: $navBg;
	font-size: 18px;
	text-align: center;
	justify-content: center;
	position: relative;
	margin: 0px;

	a {
		flex: 1;
		color: white;
		text-decoration: none;
		display: inline-block;
		line-height: 36px;
		transition: background-color .3s;

		html:not(.is-ie) & {
			max-width: 200px;
		}

		&:hover {
			background: $navHover;
		}
	}

	li {
		flex: 1;
		text-decoration: none;
		display: inline-block;
		transition: background-color .3s;
		position: relative;

		html:not(.is-ie) & {
			max-width: 200px;
		}

		&:hover {
			background: $navHover;
		}
	}

	a:hover~.sub-menu {
		display: block;
	}

	.sub-menu {
		display: none;
		position: absolute;
		background-color: $navBg;
		top: 36px;
		left: 0px;
		padding: 0;
		z-index: 3;
		white-space: nowrap;
		max-height: calc(100vh - 126px);
		overflow: auto;
		box-shadow: $shadowHover;

		ul {
			padding-top: 25px;
			list-style-type: none;
			margin: 0;
			padding: 0;
			display: block;
			column-count: 2;
			column-gap: 0;

			li {
				max-width: none;
				display: block;
				text-align: left;

				a {
					display: block;
					max-width: none;
					padding: 9px 15px;
					line-height: 18px;

					&:hover {
						background: $navHover;
					}
				}
			}

			p {
				font-size: 15px;
				margin: 0;
				padding: 0;
			}
		}

		&:hover {
			display: block;
		}
	}
}





@mixin desktop() {
	.globalHeader {
		.localSite & {
			//padding-top: 24px;
			//padding-bottom: 20px;
		}
	}

	.globalHeader-menuIcon {
		font-size: 24px;
		margin: 0;
		top: auto;
		float: none;
	}

	.globalHeader-menuTrigger {
		color: white;
		font-size: 14px;
		padding: 0;
		float: right;
	}

	.globalHeader-menu-mobile {
		display: none !important;
	}

	.globalHeader-menu-desktop {
		display: flex !important;

	}

	.globalHeader-logo {
		height: 70px;
		width: 58px;
		top: auto;
	}

	.globalHeader-logo {
		margin: 0 2px 0 0;
		display: inline-block;
		vertical-align: middle;
	}

	.globalHeader-navContainer {
		padding: 25px 0 0;
		text-align: left;
		float: right;
	}

	.globalHeader-menuIcon {
		margin: 0 0 0 5px;
	}

	.globalHeader-navContainer {
		width: auto;
	}

	.globalHeader-wrapper {
		display: block;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.globalHeader-tagline-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0;
		padding-left: 5px;
		font-size: 18px;
		line-height: 70px;
		height: 70px;
		float: right;
	}

	.globalHeader-tagline {
		display: block;
		line-height: 18px;
		color: black;
		transition: color .35s;
	}

	.globalHeader-tagline-subtitle {
		font-size: 14px;
		line-height: 14px;
		margin-top: 5px;
		display: block;
		color: #6d6e71;
		transition: color .35s;
	}

	.globalHeader .navItem-link,
	.globalHeader .globalHeader-menuTrigger {
		color: black;
	}

	.globalHeader .navItem-icon {
		color: $brand;
	}

	.globalHeader .navItem-btn {
		border-color: $brand;
		background-color: $brand;
		color: white;

		&:hover {
			background-color: white;
			color: $brand !important;
		}
	}


	// .globalHeader .globalHeader-logo {
	// 	height:42px;
	// 	width:35px;
	// 	// .localSite & {
	// 	// 	height: 43px;
	// 	// 	width: 54px;
	// 	// }
	// }

	// .globalHeader .globalHeader-navContainer {
	// 	padding-top:9px;
	// }
	.globalHeader:after {
		display: none;
	}

	.globalHeader:after {
		content: " ";
		width: 103%;
		height: 2px;
		background: rgba(0, 0, 0, .05);
		left: 0;
		position: absolute;
		bottom: -2px;
		display: block;
		z-index: 2;
	}

	.globalHeader-siteTitle {
		padding-left: 2em;
	}
}

.globalHeader .navItem-btn {
	border-color: $brand;
	background-color: $brand;
	color: white;

	&:hover {
		background-color: white;
		color: $brand !important;
	}
}

.globalHeader .location-mobile {
	@media (min-width:$screen-md-min) {
		display: none !important;
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}
