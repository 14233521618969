.heroSlider,
.heroSlider-image {
	height: 100%;
}

.heroContainer {
	display: block;
	position: relative;
	overflow: hidden;
	width: 100%;
	padding: 0;
	height: 85vw; // mobile image height = 85% of width
	min-height: 180px;
}

.heroArrow {
	display: none;
}

.hero {
	width: 100%;
	position: relative;
	display: block;
	overflow: hidden;
	height: 100%;
	background-color: black;
}

.hero.slick-slide {
	height: 100%;
}

.hero:before {
	content: '';
	position: absolute;
	z-index: 20;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(rgba(0, 0, 0, .8), rgba(0, 0, 0, .4));
	opacity: .8;
}

.heroImage-container,
.heroText-container {
	height: 100%;
	overflow: hidden;
}

.heroText-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: white;
	z-index: 40;
}

.heroText {
	top: 50%;
	transform: translate(0, -50%);
	position: relative;
	transition: all .15s;
	padding: 0 15px;

	.btn {
		padding: 8px 16px;
		font-size: 13px;
	}
}

.hero .fluid-width-video-wrapper {
	height: 100%;
	z-index: 30;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.heroImage {
	position: absolute;
	max-width: none !important;
	max-height: none !important;
	width: auto !important;
	height: 100% !important;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	opacity: 0;
	transition: opacity .35s;

	&.loaded {
		opacity: 1;
	}
}

iframe.heroImage {
	width: 100% !important;
	height: 100% !important;
	opacity: 1 !important;
}

video.heroImage {
	opacity: 1 !important;
}

.heroText-headline {
	@extend .serif;
	font-size: 24px;
	line-height: 1.2;
	margin: 0;
}

.heroText-subheadline {
	margin: .5em 0 1em;
	font-size: 15px;
}

.heroText-subheadline br {
	display: none;
}

/* Slick Slider Overrides */

.slick-dots {
	bottom: -30px;
	margin: 0;
}

.slick-list,
.slick-track {
	width: 100%;
	height: auto;
}

.slick-slide {
	height: auto;
}

.heroSlider,
.heroSlider-image {

	.slick-list,
	.slick-track,
	&.slick-slider {
		height: 100%;
	}
}

.slick-loading .slick-list {
	background: none;
}

.slick-next,
.slick-prev {
	transition: all .35s;
	z-index: 2000;
	left: 25px;
	width: 50px;
	height: 50px;
	text-align: center;
	border-radius: 50%;
	opacity: 0;
}

.slick-dots li button:before,
.slick-dots li button:after {
	font-size: 12px;
}

.heroImage-container {
	&.hasMobileBackground {
		.heroImage-desktop {
			display: none;
		}

		.heroImage-mobile {
			display: block;
		}
	}
}

@mixin tablet() {

	.slick-slider:hover .slick-next,
	.slick-slider:hover .slick-prev {
		opacity: 1;
	}

	.slick-next:hover,
	.slick-prev:hover {
		color: $brand;
	}

	.slick-next {
		right: 25px;
		left: auto;
	}

	.slick-next:before,
	.slick-prev:before {
		font: normal normal normal 20px/1 "Font Awesome 5 Pro";
		font-weight: 900;
		transition: color .35s;
	}

	.slick-prev:before {
		content: "\f053";
	}

	.slick-next:before {
		content: "\f054";
	}

	.slick-next:hover:before,
	.slick-prev:hover:before {
		color: $brand;
	}

	.slick-dots {
		bottom: 80px;
		margin: 0;
		width: auto;
		left: 50%;
		transform: translateX(-50%);
	}

	.slick-dots li button:before,
	.slick-dots li button:after {
		color: rgba(255, 255, 255, .9);
	}

	.slick-dots li.slick-active button::before {
		color: white;
	}

	.heroSlider,
	.heroSlider-image {
		height: 100%;
	}

	.heroArrow {
		bottom: 6%;
		display: block;
	}

	.heroContainer {
		height: 39vw; // desktop image height = 39% of width
	}

	.heroText-container {
		color: white;
		top: 50%;
		transform: translate(0, -50%);
		position: relative;
	}

	.heroText-container,
	.heroImage-container {
		height: auto;
		overflow: visible;
	}

	.heroText-container {
		height: 100%;
	}

	.heroText {
		padding: 0 120px;

		.btn {
			padding: 15px 35px;
			font-size: 16px;

			&+.btn {
				margin-left: 10px;
			}
		}
	}

	.heroImage {
		width: 100% !important;
		height: auto !important;

		&.inverted {
			width: auto !important;
			height: 100% !important;
		}
	}

	iframe.heroImage {
		width: 100% !important;
		height: 100% !important;
		opacity: 1;
	}

	// Bring the current slide's hero text to the forefront
	.slick-active .heroText {
		z-index: 1000;
	}

	.hero {
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center top;
		background-attachment: fixed;
		transform: translate3d(0, 0, 0);
	}

	.heroArrow {
		border: 2px solid white;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		line-height: 2;
		font-size: 25px;
		color: white;
		text-align: center;
		position: absolute;
		z-index: 30;
		margin: 0 0 0 -25px;
		left: 50%;
		bottom: 20px;
		cursor: pointer;
		transition: background .35s;

		.fa,
		.fab,
		.fas,
		.far {
			line-height: 50px;
		}

		&:hover {
			background: white;
			color: $brand;
		}
	}

	.heroText-headline {
		font-size: 33px;
		line-height: 1.3;
	}

	.heroText-subheadline {
		font-size: 15px;
	}

	.heroText-subheadline br {
		display: inline;
	}

	.heroImage-container {
		&.hasMobileBackground {
			.heroImage-desktop {
				display: block;
			}

			.heroImage-mobile {
				display: none;
			}
		}
	}
}

@media (min-width:$screen-sm-min+1px) {
	&:not(.preview-container):not(.slide-preview-custom) {
		@include tablet();
	}
}

&.preview-container.desktop,
&.preview-container.tablet {
	@include tablet();
}

@mixin desktop() {
	.heroText-container {
		padding: 0 18.5em;
	}

	.heroText {
		padding: 0 30px;
	}
}

@media (min-width:$screen-lg-min) {
	&:not(.preview-container):not(.slide-preview-custom) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}
