* {
  box-sizing: border-box;
}

// html {
//   opacity: 0;
// 	//transition: opacity .3s;
//   &.wf-active, &.wf-inactive {
//     opacity: 1;
//   }
//   &.wf-loading {
//     opacity: 0 !important; 
//   }
// }
body {
  width: 100%;
  overflow-x: hidden;

  &.hasWarning,
  &.hasError {
    &:before {
      content: "";
      background: rgba(0, 0, 0, .75);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 50;
    }
  }
}

body:not(.admin-body) {
  padding-top: 45px !important;
}

@mixin loadingIndicator() {
  width: 50px;
  height: 50px;
  display: block;
  content: "";
  border-top: 2px solid #adadad;
  border-right: 2px solid #adadad;
  border-bottom: 2px solid #adadad;
  border-left: 2px solid #2477a7;
  border-radius: 50%;
  animation: rotate .5s infinite linear, opacity 1s forwards;
  opacity: 1;
  padding: 0;
}

body,
div {
  &.loading:before {
    display: block;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9991;
    background: rgba(255, 255, 255, .8);
  }

  &.loading:after {
    @include loadingIndicator();
    position: fixed;
    top: calc(50% - 27px);
    left: calc(50% - 27px);
    z-index: 9992;
  }
}

.local-loading {
  position: relative;
  overflow: hidden;
  min-height: 60px;

  &:before {
    display: block;
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 9991;
    background: rgba(255, 255, 255, .8);
  }

  &:after {
    @include loadingIndicator();
    position: absolute;
    top: calc(50% - 27px);
    left: calc(50% - 27px);
    z-index: 9992;
  }
}

img {
  max-width: 100% !important;
  height: auto;

  .storyContent-content &,
  .pressRelease-content & {
    height: auto !important;
  }
}

.gm-style img {
  max-width: none !important;
}

iframe {
  max-width: 100% !important;
}

hr {
  border: none;
  border-top: 1px solid $greyLighter;
  margin: 1em auto;
  max-width: $container-lg;

  &.thick {
    border-width: 15px;
    max-width: none;
  }

  &.row {
    margin: 0 -15px !important;
    max-width: none;
    clear: both;
  }

  &.darker {
    border-color: #ddd;
  }
}

code,
kbd,
pre,
samp {
  font-size: 12px;
}

a {
  color: $brand;
  transition: color .35s;
}

a:focus {
  outline: none;
}

a:hover {
  color: $blueDark;
}

a[name] {
  position: relative;
  top: -45px;
  display: block;
  visibility: hidden;
}

main {
  max-width: 100%;
  width: 100%;
  display: block;
}

.icon {
  background-size: contain;
  background-position: center center;
  width: auto;
  height: 75px;
  display: block;
  margin: 0 auto;
}

.icon {

  &.fa,
  &.fab,
  &.far,
  &.fas,
  &.fad,
  &.fal {
    font-size: 75px;
    color: $blueDark;
  }
}

.col-md-4 .icon {
  height: 75px;
}

// Add padding to the bottom of the last container on a page, before the footer
// Allows the footer shield to have some breathing room

#page>div:last-of-type:not(.landingpage-container),
#page>.landingpage-container>div:last-of-type {
  padding-bottom: 75px;
}

body.searchresults:not(.admin-body) {
  padding-top: 525px !important;
}

@mixin desktop() {
  body:not(.admin-body) {
    padding-top: 114px !important;
  }

  a[name] {
    top: -126px;
  }

  .icon {
    height: 150px;
  }

  .icon {

    &.fa,
    &.fab,
    &.far,
    &.fas,
    &.fad,
    &.fal {
      font-size: 145px;
      color: $blueDark;
    }
  }

  hr {
    margin: 2em auto;

    .localSite & {
      margin: 1em auto;
    }
  }
}

@media (min-width:$screen-md-min) {
  &:not(.preview-container) {
    @include desktop();
  }
}

&.preview-container.desktop {
  @include desktop();
}

@media (max-width:$screen-xs-max) {
  [class*="col-md-4"] {
    margin-bottom: 2em;
  }
}


.youtube {
  /* container (responsive) */
  position: relative;
  cursor: pointer;
  background: #ccc no-repeat center center / cover;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0px;
  clear: both;
}

.youtube::before {
  /* play bubble */
  content: '';
  background: rgba(255, 255, 255, .6);
  height: 80px;
  width: 80px;
  display: block;
  margin: -40px 0 0 -40px;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 0 20px 3px rgba(51, 51, 51, .8);
  transition: all .1s ease;
  z-index: 1;
}

.youtube:hover::before {
  /* play bubble hover */
  background: rgba(238, 238, 238, .8);
  box-shadow: 0 0 10px 3px rgba(51, 51, 51, .8);
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
}

.youtube::after {
  /* Play Triangle */
  content: '';
  width: 0;
  height: 0;
  line-height: 0px;
  display: block;
  border-style: solid;
  border-width: 20px 0 20px 40px;
  margin: -20px 0 0 -15px;
  border-color: transparent transparent transparent #333;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 0;
  width: 0;
  z-index: 2;
}

.youtube iframe {
  /* youtube embed (responsive) */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

table {

  .freeTextArea &,
  .whiteTitleArea & {
    border-color: transparent;
  }
}

iframe[id^=bt-pixels] {
  display: none;
}

.gm-style-iw {
  text-align: left;
}
