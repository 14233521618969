.shareCard {
	text-align: center;
	border-radius: 4px;
	box-shadow: 0 0 5px rgba(0, 0, 0, .25);
	padding: 25px 0 50px;
	margin: 0 0 10px;
	position: relative;
}

.shareCard-share {
	text-align: right;
	padding-right: 10px;
	font-size: 13px;
	position: absolute;
	left: 0;
	width: 100%;
	bottom: 10px;
}

.shareCard-icon {
	color: white;
	background: $greyLight;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	line-height: 30px;
	transition: background .35s;
	font-size: 14px;
	cursor: pointer;
	text-align: center;
	display: inline-block;
	vertical-align: middle;

	.fa,
	.fab,
	.far,
	.fas,
	.fad,
	.fal {
		line-height: 30px;
	}

	&:hover {
		background: $blueDark;
		color: white;
	}

	&:first-child {
		margin-left: 10px;
	}
}

.shareCard-stat,
.shareCard-cta {
	font-size: 14px;
	@extend .u-textLeft;
	padding: 0 10px 0 20px;
	margin-bottom: 25px;
	border-left: 10px solid transparent;
}

.shareCard-stat {
	border-color: $brand;
}

.shareCard-cta {
	border-color: $blueLight;
	display: flex;
	flex-direction: column;
	align-items: center;

	a {
		text-decoration: none;
		display: block;
		padding: 15px 0 0;
		font-weight: 600;
	}

	.shareable-btn {
		margin-top: 15px;
		padding: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #ed1c2b;
		color: #fff;
		text-decoration: none;
		font-weight: 600;
		color: white;
		height: 30px;
		width: 120px;
		border-radius: 6px;
	}
}

@mixin desktop() {
	.shareCard-container {
		display: flex;
		align-items: center;
		align-items: stretch;
	}

	.shareCard {
		flex: 1;
		margin: 0 10px 10px;
	}

	.shareCard:last-child {
		margin-right: 0;
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}
