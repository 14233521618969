.boardChairQuote {
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	background-color: $greyLight;
	background-position:fixed;
	min-height: 380px;
	max-height: 100vh;
	overflow: hidden;
	position: relative;
	margin: 0 auto;
	font-size: 18px;
	line-height: 28px;
	color: white;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.boardChairQuote-content {
		position: relative;
		z-index: 1;
		margin-top: 20px;
		margin-right: 20px;
		margin-bottom: 20px;
		margin-left: 20px;
		.boardChairQuote-name {
			margin-top: 20px;
		}
	}
	&.right {
		text-align: right;
		.boardChairQuote-content {
			margin-left: 30%;
		}
	}
	&.left {
		text-align: left;
		.boardChairQuote-content {
			margin-right: 30%;
		}
	}
}

@mixin tablet() {
	.boardChairQuote {
		.boardChairQuote-content {
			margin-top: 50px;
			margin-right: 50px;
			margin-bottom: 50px;
			margin-left: 50px;
		}
		&.right {
			.boardChairQuote-content {
				margin-left: 50%;
			}
		}
		&.left {
			.boardChairQuote-content {
				margin-right: 50%;
			}
		}
	}
}

@media (min-width:$screen-sm-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}

&.preview-container.desktop, &.preview-container.tablet {
	@include tablet();
}