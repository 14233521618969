.givingToolbar-toolbar.national-theme {
	background: #34465f;
	height: 37px;
	position: relative !important;
	top: unset !important;
	left: unset !important;
	right: unset !important;

	.flyoutNav {
		.flyoutNavItem {
			&:hover {
				background: #0c213c !important;

				ul.flyoutNav {
					top: 37px !important;
					display: grid !important;
					grid-auto-flow: row;
					grid-template-columns: 250px 250px !important;
					grid-column-gap: 10px;
					grid-template-rows: repeat(auto-fit, minmax(30px, 43px));
					align-items: center;
					overflow: hidden;
					width: auto;
					max-width: 510px !important;

					li {
						min-height: 43px !important;
						overflow: hidden;
						white-space: normal;

						a {
							font-size: 15px;
							line-height: 1.3 !important;
							text-transform: capitalize;
						}
					}

					li.flyoutNavItem.hasChildren.viewingChild {
						ul.flyoutNav {
							padding-top: 50px;
							top: 0 !important;

							li.flyoutNavItem-back {
								position: absolute;
								top: 0;
								right: 0;
								left: 0;

								a {
									padding-left: 35px !important;

									i {
										top: -3px;
									}
								}
							}
						}
					}
				}

				ul.flyoutNav.viewingChild {
					position: absolute;
					top: inherit !important;

					li.flyoutNavItem:not(.viewingChild>ul>li) {
						pointer-events: none;
					}
				}


				ul.flyoutNav::before {
					position: absolute;
				}

				ul.flyoutNav::after {
					position: absolute;
				}
			}

			ul {
				position: absolute;
				background-color: #34465f;
				// top: 36px;
				left: 0;
				right: auto !important;
				padding: 0;
				z-index: 3;
				white-space: nowrap;
				max-height: calc(100vh - 126px);
				overflow: auto;
				background: #34465f !important;

				li {
					max-width: none;
					display: block;
					text-align: left !important;
					background: #34465f !important;
					flex: 1;

					&:hover {
						background: #0c213c !important;
					}

					a {
						color: white !important;
						display: block;
						max-width: none;
						padding: 9px 15px !important;
						line-height: 18px;
						width: 100%;
						flex: 1;
						text-align: left !important;
					}
				}
			}
		}

		li {
			a {
				height: unset !important;
				line-height: 2 !important;
			}
		}
	}
}

.givingToolbar-toolbar.isMainNav.national-theme ul.flyoutNav li.flyoutNavItem.hasChildren>ul.flyoutNav li.flyoutNavItem.hasChildren>a.flyoutNavItem-link {
	padding-left: 15px !important
}

.givingToolbar-toolbar.isMainNav ul.flyoutNav li.flyoutNavItem.hasChildren>ul.flyoutNav li.flyoutNavItem a.flyoutNavItem-link:hover {
	background: transparent !important;
}
