
$nr-blueDark: #0a2644;
$nr-blueMd: #3c5169;
$nr-blueLight: #6c7d8e;
$nr-greyBlue: #e0ebef;
$nr-greyLight: #f0f1f0;

.newsroom-btn1 {
    background-color:$nr-blueDark!important;
    border-color:$nr-blueDark!important;
    &:hover {
        background-color:transparent!important;
        color:$nr-blueDark!important;
    }
}
.newsroom-btn2 {
    background-color:$nr-blueMd!important;
    border-color:$nr-blueMd!important;
    &:hover {
        background-color:transparent!important;
        color:$nr-blueMd!important;
    }
    &.btn-inverse {
        background-color:transparent!important;
        color:$nr-blueMd!important;
        &:hover {
            color: white !important;
            background-color:$nr-blueMd!important;
            border-color:$nr-blueMd!important;
        }
    }
}
.newsroom-btn3 {
    background-color:$nr-blueLight!important;
    border-color:$nr-blueLight!important;
    &:hover {
        background-color:transparent!important;
        color:$nr-blueLight!important;
    }
}
.newsroom-section-item {
    max-width: 190px;
    margin: 0 auto;
    i {
        color: $nr-blueMd;
        font-size: 50px;
        height: 80px;
        display: inline-block;
        line-height: 80px;
        position: relative;
        span {
            position: absolute;
            color: $nr-blueMd;
        }
    }
    .btn {
        padding: 3px 0;
        margin-bottom: 15px;
        text-transform: uppercase;
        font-size: 15px;
    }
}

.prContacts {
    max-width: 620px;
    .btn {
        background: transparent;
        padding: 3px 0;
        max-width: 320px;
        margin: 30px auto;
        font-size: 16px;
    }
    .row {
        margin-left: -1px;
        margin-right: -1px;
    }
    .col-xs-12, .col-sm-6 {
        padding-left: 1px;
        padding-right: 1px;
        padding-bottom: 2px;
    }
    .top-section {
        background: $nr-greyBlue;
        padding: 30px;
        p {
            margin-bottom: 45px;
        }
    }
    .bottom-section {
        background: $nr-greyLight;
        padding: 15px;
        p:first-child {
            margin-top: 0;
            b {
                font-size: 110%;
            }
        }
        p:last-child {
            margin-bottom: 0;
        }
    }
}

.annualReports {
    .row {
        position: relative;
    }
    .ar-featured {
        background: $nr-greyLight;
        padding: 15px;
        .ar-img {
            height: 300px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin: -15px -15px 15px;
        }
        b {
            font-size: 110%;
            margin-bottom: 10px;
            display: block;
        }
        .btn {
            background: transparent;
            padding: 3px 30px;
            margin-top: 15px;
            font-size: 16px;
            &:hover {
                background: $brand;
            }
        }
    }
    .scrollarea {
        overflow: auto;
        position: absolute;
        top: 0;
        right: 15px;
        bottom: 0;
        // width: 50%;
        // padding: 0 15px;
        .ar-item {
            position: relative;
            background: $nr-greyLight;
            padding: 15px;
            margin-bottom: 2px;
            height: 84px;
            .ar-img {
                float: left;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 74px;
                width: 150px;
                margin: -10px 15px -10px -10px;
            }
            .ar-flex-container {
                display: flex;
                flex-direction: column;
                height: 54px;
                justify-content: center;
                .ar-links {
                    height: 20px;
                    a {
                        font-size: 13px;
                        text-decoration: none;
                        padding-right: 15px;
                        float: left;
                        position: relative;
                        &:after {
                            content: '|';
                            position: absolute;
                            right: 0;
                        }
                        &:last-child {
                            &:after {
                                content: none;
                            }
                            padding-left: 15px;
                        }
                    }
                    &:after {
                        content: '';
                        display: table;
                        clear: both;
                    }
                }
            }
            .btn {
                background: transparent;
                padding: 3px 30px;
                margin: 10px 0 0;
                font-size: 16px;
                &:hover {
                    background: $brand;
                }
            }
        }
        @media (max-width:$screen-sm-max) {
            position: relative;
            top: auto;
            right: auto;
            bottom: auto;
            margin-top: 5px;
            max-height: 400px;
            .ar-item {
                padding-bottom: 58px;
                height: auto;
                .btn {
                    position: absolute;
                    right: 15px;
                    bottom: 15px;
                }
            }
        }
    }
}