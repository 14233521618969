.rowStory .btn {
	display:block;
	max-width: 100%;
}

.rowStory-photo:first-child {
	text-align:right;
}

.rowStory-photo img {
	vertical-align: bottom;
	width: 100%;
	height: auto;
}

.rowStory-caption {
	padding:30px;
}

.rowStory-caption.u-textRight {
	text-align: left;
}

.rowStory {
	margin: -1em 0;
}

@mixin desktop() {
	.rowStory {
		display:flex;
		align-items:center;
		//override the parent .section padding
		margin:-2em auto;
		max-width:1600px;
	}
	.rowStory .btn {
		max-width:48%;
	}
	.rowStory-photo,
	.rowStory-caption {
		flex:1;
	}
	.rowStory-caption {
		flex-grow:2;
		padding:0 30px;
	}
	.rowStory-photo {
		min-height:200px;
		overflow: hidden;
	}
	.rowStory-caption.u-textRight {
		text-align: right;
	}
	.rowStory-caption.u-textRight .btn {
		float: right;
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}