.givingToolbar-toolbar {
	background: $brand;
	display: flex;
	overflow: auto;
	transform: translate3d(0, 0, 0);

	>div,
	>a {
		flex: 1 1 auto;
		display: flex;
		justify-content: center;
		color: white;
		text-transform: unset;
		padding: 5px 0;
		cursor: pointer;
		transition: all .35s;

		&:hover {
			background: $brandLight;
			text-decoration: none;
		}

		i {
			height: 38px;
			line-height: 38px;
			font-size: 28px;
		}

		span.line {
			line-height: 38px;

			span {
				display: none;
			}
		}
	}

	.localSite & {
		top: 45px;
		left: 0;
		width: 100%;
		position: fixed;
		font-size: 13px;
		z-index: 500;

		&.hasAlert {
			top: 113px;
		}

		@media (min-width:1450px) {
			font-size: 15px;
		}

		@media (min-width:$screen-md-min) {
			top: 78px !important;

			&.hasAlert {
				top: 113px;
			}

			//font-size: 15px;
		}
	}

	.localSite.home &,
	.localSite.ministry & {
		position: absolute;
		top: auto;
		left: 0;
		top: 70vh;

		&.navbar-fixed {
			top: 45px;
			position: fixed;
			z-index: 500;

			&.hasAlert {
				top: 113px;
			}

			@media (min-width:$screen-md-min) {
				top: 66px;

				&.hasAlert {
					top: 113px;
				}
			}
		}
	}

	&.isMainNav>ul.flyoutNav>li.flyoutNavItem>a.flyoutNavItem-link {
		text-transform: unset;
		line-height: 29px !important;
	}

	body:not(.localSite) & {
		display: none;
	}
}

@mixin tablet() {
	.givingToolbar-toolbar {

		&.isMainNav {
			font-size: 18px;

			@media (min-width:1450px) {
				font-size: 18px;
			}
		}

		>div,
		>a {
			i {
				float: left;
				margin-right: 14px;
			}

			span.line {
				span:not(.tablet) {
					display: none;
				}

				span.tablet {
					display: inline;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	:not(.preview-content)> {
		@include tablet();
	}
}

&.preview-container.tablet,
&.preview-container.desktop {
	@include tablet();
}

@mixin desktop() {
	.givingToolbar-toolbar {

		>div,
		>a {
			padding: 25px 0;

			.localSite & {
				padding: 14px 0;
			}

			i {
				font-size: 34px;

				.localSite:not(.home):not(.ministry) & {
					font-size: 20px;
					height: auto;
					line-height: 20px;
				}
			}

			span.line {
				line-height: 18px;
				display: block;

				.localSite:not(.home):not(.ministry) & {
					line-height: 1;
					display: inline;
				}

				span {
					display: inline;
				}
			}
		}

		&.navbar-fixed {

			>div,
			>a {
				i {
					.localSite & {
						font-size: 20px;
						height: auto;
						line-height: 20px;
					}
				}

				span.line {
					.localSite & {
						line-height: 1;
						display: inline;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	:not(.preview-content)> {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}

.givingToolbar-links {
	color: $greyMedium;

	>div,
	>a {
		display: flex;
		padding: 10px 0;

		>* {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.givingToolbar-link {
			text-transform: unset;
			white-space: nowrap;
			width: 190px;

			>span {
				height: 38px;

				i {
					float: left;
					margin-right: 14px;
					font-size: 28px;
					line-height: 38px;
					height: 38px;
				}
			}
		}

		>i {
			width: 110px;
			font-size: 28px;
			text-align: center;
		}

		.givingToolbar-description {
			flex: 1;
		}
	}
}

.givingToolbar-text {
	display: inline-block;
	vertical-align: middle;
}

.emailCaptureModal {
	margin: 0 auto;
	background: $brand;
	color: white;
	max-width: 580px;
	padding: 2em;
	border-radius: 5px;
	position: relative;

	.mfp-close {
		color: white;
	}

	input[type="submit"] {
		border: 1px solid white;
	}

	.facebookLink {
		color: white;
		font-size: 12px;
		line-height: 1;
		text-decoration: none;
		display: block;
		padding: 1em 0 0;
		margin: 2em 0 0;
		font-weight: 100;
		opacity: 1;

		&:hover {
			opacity: .7;
			transition: opacity .35s;
		}

		strong {
			font-weight: 600;
			display: block;
		}

		i {
			color: $brand;
			background: white;
			width: 20px;
			height: 20px;
			text-align: center;
			line-height: 20px;
			border-radius: 50%;
			margin: 2px 0 0 5px;
		}
	}
}

.givingToolbar-toolbar>a {
	text-decoration: none;
}
