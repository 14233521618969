@mixin tablet() {

	.slick-slider:hover .slick-next,
	.slick-slider:hover .slick-prev {
		opacity: 1;
	}

	.slick-next:hover,
	.slick-prev:hover {
		color: $brand;
	}

	.slick-next {
		right: 25px;
		left: auto;
	}

	.slick-next:before,
	.slick-prev:before {
		font: normal normal normal 20px/1 "Font Awesome 5 Pro";
		font-weight: 900;
		transition: color .35s;
	}

	.slick-prev:before {
		content: "\f053";
	}

	.slick-next:before {
		content: "\f054";
	}

	.slick-next:hover:before,
	.slick-prev:hover:before {
		color: $brand;
	}

	.slick-dots {
		bottom: 20px !important;
		margin: 0;
		width: auto;
		left: 50%;
		transform: translateX(-50%);
	}

	.slick-dots li button:before,
	.slick-dots li button:after {
		color: rgba(255, 255, 255, .9) !important;
	}

	.slick-dots li.slick-active button::before {
		color: white;
	}

	.heroSlider,
	.heroSlider-image {
		height: 100%;
	}

	.heroArrow {
		bottom: 6%;
		display: block;
	}

	.heroContainer {
		height: 30vw; // desktop image height = 39% of width
	}

	.heroText-container {
		color: white;
		top: 50%;
		transform: translate(0, -50%);
		position: relative;
	}

	.heroText-container,
	.heroImage-container {
		height: auto;
		overflow: visible;
	}

	.heroText-container {
		height: 100%;
	}

	.heroText {
		padding: 0 120px;

		.btn {
			padding: 15px 35px;
			font-size: 16px;

			&+.btn {
				margin-left: 10px;
			}
		}
	}

	.heroImage {
		width: 100% !important;
		height: auto !important;

		&.inverted {
			width: auto !important;
			height: 100% !important;
		}
	}

	iframe.heroImage {
		width: 100% !important;
		height: 100% !important;
		opacity: 1;
	}

	// Bring the current slide's hero text to the forefront
	.slick-active .heroText {
		z-index: 1000;
	}

	.hero {
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center top;
		background-attachment: fixed;
		transform: translate3d(0, 0, 0);
	}

	.heroArrow {
		border: 2px solid white;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		line-height: 2;
		font-size: 25px;
		color: white;
		text-align: center;
		position: absolute;
		z-index: 30;
		margin: 0 0 0 -25px;
		left: 50%;
		bottom: 20px;
		cursor: pointer;
		transition: background .35s;

		.fa,
		.fab,
		.fas,
		.far {
			line-height: 50px;
		}

		&:hover {
			background: white;
			color: $brand;
		}
	}

	.heroText-headline {
		font-size: 33px;
		line-height: 1.3;
	}

	.heroText-subheadline {
		font-size: 15px;
	}

	.heroText-subheadline br {
		display: inline;
	}

	.heroImage-container {
		&.hasMobileBackground {
			.heroImage-desktop {
				display: block;
			}

			.heroImage-mobile {
				display: none;
			}
		}
	}
}


@media (min-width:$screen-sm-min+1px) {
	&:not(.preview-container):not(.slide-preview-custom) {
		@include tablet();
	}
}

&.preview-container.desktop,
&.preview-container.tablet {
	@include tablet();
}
