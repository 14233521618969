.localSites-map-container {
    height: 380px;
	max-height: 100vh;
    overflow: hidden;
    position: relative;
	margin: 0 auto;
    &:before {
        //.touchevents & {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 20;
        //}
    }
    &.isDraggable {
        &:before {
            display: none;
        }
    }
	#localSites-map-iframe {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%) !important;
		min-height: 100%;
		min-width: 100%;
        z-index: 0;
    }
}

.localSites-items {
    height: auto;
	max-height: 100vh;
    overflow: auto;
    position: relative;
	margin: 0;
    .localSites-item {
        position: relative;
        background-color: #f5f5f5;
        border-bottom: 1px solid #e5e5e5;
        padding: 20px 20px 20px 40px;
        cursor: pointer;
        transition: background-color .35s;
        .localSites-name {
            line-height: 20px;
            margin: 5px 0;
            font-size: 15px;
            color: $blueDark;
        }
        .localSites-address {
            line-height: 20px;
            margin: 5px 0;
            font-size: 14px;
            color: $blueDark;
        }
        .localSites-phone, .localSites-website {
            line-height: 20px;
            margin: 5px 0;
            font-size: 12px;
            text-decoration: none;
            white-space: nowrap;
        }
        .localSites-directions {
            white-space: nowrap;
            div {
                line-height: 20px;
                margin-bottom: 5px;
                font-size: 12px;
                color: #95989A;
            }
            a {
                line-height: 20px;
                margin: 5px 0;
                font-size: 18px;
                margin: 0 6px;
                text-decoration: none;
                color: #4F4F4F;
                &:hover {
                    color: $blueDark;
                }
            }
        }
        &.isActive {
            background-color: #fff;
			background-image: linear-gradient(to bottom, #dbdbdb 0%, rgba(219,219,219,.6) 100%);
        }
        &:hover {
            background-color: #dbdbdb;
        }
        &:before {
            content: " ";
            width: 24px;
            height: 39px;
            background: url('//static.salvationarmy.org/us-east-1/templates/symphony/static_resources/images/global/location-pin.png') no-repeat top center;
            position: absolute;
            top: 24px;
            left: 10px;
            background-size: 80%;
        }
    }
}

@mixin tablet() {
	.localSites-map-container, .localSites-items {
		max-height: 50vh;
	}
}

@mixin desktop() {
    .localSites-items {
        height: 380px;
    }
}

@media (min-width:$screen-sm-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}

@media (min-width:$screen-md-min) {
    &:not(.preview-container) {
        @include desktop();
    }
}
