.globalFooter {
	font-size:14px;
	line-height:1.3;
	background:$blueDark;
	color:white;
	padding:3em 0 1em;
	position:relative;
	z-index:100;
	clear: both;
}

.globalFooter-wrapper {
	@extend .container;
}

.globalFooter-section {
	@extend .row;
}
.globalFooter .right-column{
	padding-right:20px;
	text-align:right;
	@media (max-width:$screen-sm-min) {
		text-align: center;
	}
}
.globalFooter:before {
	content:"";
	display:block;
	background:url(../images/global/shield.svg) no-repeat center center;
	background-size:contain;
	width:64px;
	height:76px;
	position:absolute;
	top:-38px;
	left:50%;
	margin:0 0 0 -33px;
}

.icon-x-twitter:before {
	content: url(../images/global/x-twitter.svg);
	display: inline-block;
  	width: 17.44px;
  	height: 18px;
	padding-top : 2.5px;
	fill: #2a313f;
	text-align: center;
}

.globalFooter-copyright {
	color:#5a5d64;
	font-size:13px;
	line-height: 1.4;
	padding: 0 1em;
}

.globalFooter-copyrightLink {
	color:#5a5d64;
	@extend .u-linkReset;
}

.globalFooter-copyrightLink:hover {
	color:$greyLight;
}

.globalFooter-search {
	display: flex;
	position:relative;
	overflow:hidden;
	max-width: 90%;
	margin: 0 auto;
}

input:not([type=submit]):not([type=radio]):not([type=checkbox]).globalFooter-input {
	flex-grow:1;
	margin:0 5px 0 0;
	color:white;
	border:none;
	border:1px solid #41454e;
	background:#41454e;
	padding:10px;
	border-radius: 4px;
}

input[type="submit"].globalFooter-submit {
	background:rgb(105,109,120);
	color:white;
	margin: 0;
	padding: 2px 8px 3px;
	font-size: 12px;
	min-width: 50px;
}

input[type="submit"].globalFooter-submit:hover {
	background:$greyLight;
}

.globalFooter-nav {
	@extend .nav;
	margin:0 0 1em 0;
	text-align: center;
}

.globalFooter-navLink {
	@extend .globalFooter-copyrightLink;
	display:block;
	padding:3px 0;
	font-size:14px;
	color:white;
}

.globalFooter-navLink:hover {
	color:$greyLight;
}

.socialMedia-container {
	padding: 20px 0 0;
	text-align: center;
	display: flex;
	justify-content: center;
	span {
		flex: 1;
		max-width: 100px;
		min-width: 46px;
		a {
			color: $blueDark;
			font-size: 18px;
			background: white;
			display: inline-block;
			text-decoration: none;
			margin: 5px 0 15px;
			width: 36px;
			height: 36px;
			text-align: center;
			line-height: 36px;
			border-radius: 50%;
			transition: opacity .3s;
			i {
				line-height: 36px;
			}
			&:hover {
				opacity: .5;
			}
		}
	}
}

.globalFooter hr {
	border:none;
	border-top:1px solid $grey;
	margin:2em 0;
}

@mixin tablet() {
	.globalFooter-nav {
		margin:0;
		text-align: left;
	}
	.globalFooter-search {
		margin-top: 1em;
		max-width: 100%;
	}
	.globalFooter-copyright {
		padding: 0;
	}
}

@mixin desktop() {
	input[type="submit"].globalFooter-submit {
		font-size: 14px;
		min-width: 80px;
	}
}

@media (min-width:$screen-sm-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop, &.preview-container.tablet {
	@include tablet();
}
