.donateHeader {
	@extend .container-fluid;
	//display: none;
	transition:max-height .35s;
	max-height:0;
	overflow:hidden;
	display: block;
	width: 100%;
	height: calc(100vh - 40px);
	&.isExpanded {
		max-height: calc(100vh - 40px);
		overflow: auto;
		padding-bottom: 20px;
	}
	i.js-donateHeader{
		font-size: 2em;
    color: #ddd;
		@media (max-width:991px) {
			position: relative;
			left: 60%;
			top: 10px;
		}
		@media (max-width:768px) {
			position: relative;
			left: 95%;
			top: 10px;
		}
		@media (max-width:468px) {
			position: relative;
			left: 90%;
			top: 10px;
		}
	}
}

.donateHeader-goods {
	display: flex;
	align-items:center;
}

.donateHeader-recurring {
	flex: 1;
	& + & {
		//padding-left:25px;
	}
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom:0;
	}
}

.donateHeader-amounts .btn.isSelected {
	background:$brand;
	color:white;
}


.donateHeader-zip {
	position: relative;
	width: 100%;
}

.donateHeader-geolocate {
	position: absolute;
	right: 7px;
	top: 12px;
	cursor: pointer;
}

input:not([type=submit]):not([type=radio]):not([type=checkbox]).donateHeader-zipInput {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right:0;
	//max-width: 150px;
	padding-right:25px;
	//margin-right: -4px;
}

input[type="submit"].donateHeader-zipSubmit {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	padding-top: 11px;
	font-size: 18px;
}

.donateHeader-monetary {
	padding-top: 0;
	margin-top: 1em;
	border-top:2px solid $greyLighter;
}

.donateHeader-amounts {
	margin-bottom: 20px;
	.btn {
		border-width: 1px;
		font-weight: 700;
		font-size: 14px;
		padding: 8px 25px;
		display: block;
		margin-bottom: 10px;
	}
}

.donateHeader-submit {
	.btn {
		display: block;
	}
}

@mixin tablet() {
	.donateHeader {
		height: auto;
		&.isExpanded {
			max-height:999px;
		}
	}
	.donateHeader-monetary {
		padding-top: 0;
		margin-top: 0;
		border: none;
		//padding-left: 2em;
		//margin-left: 1em;
		border-left:2px solid $greyLighter;
	}

	.donateHeader-amounts {
		display: flex;
		align-items:center;
		margin-bottom: 20px;
		.btn {
			flex: 1;
			& + & {
				//padding-left:25px;
			}
			> *:first-child {
				margin-top: 0;
			}
			> *:last-child {
				margin-bottom:0;
			}
			margin-bottom: 0;
			margin-right:10px;
			padding:10px 10px;
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.donateHeader-submit {
		flex: 1;
		& + & {
			//padding-left:25px;
		}
		> *:first-child {
			margin-top: 0;
		}
		> *:last-child {
			margin-bottom:0;
		}
		float: right;
		text-align:right;
		.btn {
			display: inline-block;
			padding:5px 50px;
		}
	}
}

@mixin desktop() {
	.donateHeader-recurring label,
	.donateHeader-recurring input[type="checkbox"] {
		width: auto;
	}
	.donateHeader-recurring label {
		//font-size: 14px;
	}

	.donateHeader-zip {
		//max-width: 230px;
	}

	.donateHeader-amounts {
		.btn {
			margin-right:25px;
			padding:10px 25px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

@media (min-width:$screen-sm-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}
@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.tablet {
	@include tablet();
}
&.preview-container.desktop {
	@include desktop();
}
