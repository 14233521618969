/* Accordion styles */
.tab {
    position: relative;
    margin-bottom: 1px;
    width: 100%;
    overflow: hidden;

    >input[type="checkbox"],
    >input[type="radio"] {
        position: absolute !important;
        opacity: 0;
        z-index: -1;

        +label {
            position: relative;
            display: block;
            padding: 0 0 0 15px;
            background: #b3b5b4;
            font-weight: bold;
            line-height: 3;
            cursor: pointer;

            color: white;

            &.u-whiteBg {
                background: white;
                color: black;
            }

            &.u-blackBg {
                background: black;
                color: white;
            }

            &.u-greyBg {
                background: $grey;
                color: white;
            }

            &.u-greyLightBg {
                background: $greyLight;
                color: black;
            }

            &.u-redBg {
                background: $brand;
                color: white;
            }

            &.u-greenBg {
                background: $green;
                color: white;
            }

            &.u-blueDarkBg {
                background: $blueDark;
                color: white;
            }

            &::after {
                content: "+";
                position: absolute;
                right: 0;
                top: 0;
                display: block;
                width: 3em;
                height: 3em;
                line-height: 3;
                text-align: center;
                opacity: 1;
                transition: all 0.35s;
            }

            +.tab-content {
                max-height: 0;
                overflow: hidden;
                background: #fff;
                color: black;
                transition: max-height 0.35s, padding 0.35s;

                .accordionGroup & {
                    padding: 0 15px;
                }

                p {
                    margin: 1em 15px;

                    .accordionGroup & {
                        margin: 1em 0;
                    }
                }
            }
        }

        &:checked {
            +label {
                &::after {
                    transform: rotate(315deg);
                }
            }

            ~.tab-content {
                max-height: 2000px;

                .accordionGroup & {
                    padding: 15px;
                }
            }
        }

        &[type="radio"] {
            &:checked {
                +label {
                    &::after {
                        opacity: 0;
                    }
                }
            }
        }
    }
}
