.flyoutContainer {
	background: #7f838c;
	position: fixed;
	z-index: 3000;
	top: 0;
	right: 0;
	width: 100%;
	padding: 0;
	height: 100%;
	font-size: 15px;
	line-height: 24px;
	transition: transform .35s;
	transform: translate3d(100%, 0, 0);

	&.isVisible {
		transform: translate3d(0, 0, 0);
	}

	.flyoutNav {
		height: 100%;
		width: 100%;
		@extend .nav;

		padding: 53px 30px 30px;
		overflow: auto;

		.flyoutNavItem {
			position: relative;
			margin: 0 -30px;
			padding: 0 30px;

			>ul {
				list-style: none;
				margin: 0 -30px;
				padding: 0 30px;
				background: rgba(42, 49, 63, .3);
				overflow: hidden;
				height: 100%;
				max-height: 0;
				transition: max-height .35s;
				box-shadow: inset 0 3px 7px rgba(0, 0, 0, .3);
			}

			&.viewingChild {
				>ul {
					max-height: 800px;
				}

				>.flyoutNavItem-link+i {
					&:before {
						transform: rotate(180deg);
					}
				}
			}

			.flyoutNavItem-link {
				color: white;
				text-decoration: none;
				font-weight: 300;
				display: block;
				padding: 10px 0;
				border-bottom: 1px solid rgba(0, 0, 0, .1);
				transition: all .35s;

				&.hasChildren {
					padding-right: 40px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				&+i {
					position: absolute;
					right: 30px;
					top: 0;
					color: white;
					width: 40px;
					text-align: center;
					height: 44px;
					line-height: 45px;
					cursor: pointer;

					&:before {
						display: inline-block;
						transition: transform .35s;
						transform: rotate(0);
					}

					&:hover {
						background: rgba(0, 0, 0, .1);
					}
				}

				&:hover {
					color: rgba(0, 0, 0, .6);
				}
			}
		}

		>.flyoutNavItem {
			&:last-child .flyoutNavItem-link {
				border-color: transparent;
			}
		}
	}

	.flyoutClose {
		color: white;
		position: absolute;
		right: 10px;
		top: 15px;
		width: 25px;
		height: 25px;
		font-size: 28px;
		cursor: pointer;
	}

	.flyoutClose:hover .flyoutClose-icon {
		fill: $greyDark;
	}

	.flyoutClose-icon {
		fill: white;
		max-width: 100%;
		height: 25px;
		transition: fill .35s;
	}
}

.no-svg .flyoutClose:after {
	content: "x";
}

@mixin tablet() {
	.flyoutContainer {
		max-width: 300px;
	}
}

@mixin desktop() {
	.flyoutContainer {
		&.mobile-only {
			display: none;
		}
	}
}

@media (min-width:$screen-sm-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}

&.preview-container.desktop,
&.preview-container.tablet {
	@include tablet();
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}
